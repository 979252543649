import React, { useState } from 'react';
import { useFormik } from 'formik';
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  Box,
} from '@material-ui/core/';
import { Link } from 'gatsby';
import RecaptchaInput from '../components/RecaptchaInput/RecaptchaInput';
import FormTextInput from '../components/FormTextInput/FormTextInput';
import FormSubmission from '../components/FormSubmission/FormSubmission';
import Layout from '../components/layout';
import SEO from '../components/seo';
import FormPrivacyCheckbox from '../components/FormPrivacyCheckbox/FormPrivacyCheckbox';

import {
  coreBlue,
  coreRed,
  coreWhite,
  coreLightGrey,
} from '../themeprovider/ccmTheme/colorPalette';
import { sendContactForm } from '../api';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    left: '20px',
    [theme.breakpoints.up('lg')]: {
      left: '200px',
    },
    width: '80%',
    marginTop: '20px',
    marginBottom: '62px',
    padding: '0px 30px 30px 30px',
    backgroundColor: 'rgba(249, 249, 249, 0.3)',
    border: '1px solid #E0E0E0',
  },
  contactHeader: {
    marginRight: '20px',
    marginTop: '50px',
    paddingLeft: '0px',
    marginLeft: '20px',
    [theme.breakpoints.up('lg')]: {
      marginLeft: '200px',
    },
    fontFamily: theme.typography.h1,
    fontSize: '18px',
    lineHeight: '140%',
    [theme.breakpoints.down('xs')]: {
      marginTop: '30px',
      width: '80%',
    },
  },
  reCaptchaContainer: {
    margin: '30px auto 0',
  },
  error: {
    paddingTop: '30px',
    fontSize: '14px',
    color: coreRed,
  },
  submitButton: {
    backgroundColor: coreBlue,
    borderRadius: '50px',
    marginTop: '30px',
    fontWeight: 600,
    fontSize: '18px',
    color: coreWhite,
    lineHeight: '20px',
    alignItems: 'center',
    textAlign: 'center',
    letterSpacing: '0.0357143em',
    textTransform: 'uppercase',
    width: '198px',
    height: '55px',
  },
  hyperLink: {
    color: coreRed,
    textDecoration: 'underline',
    fontSize: '24px',
    position: 'relative',
    marginTop: '21px',
    border: 'none',
    backgroundColor: coreLightGrey,
    cursor: 'pointer',
  },
  hyperlinkService: {
    color: coreRed,
    horizontalMargin: 200,
  },
  privacyCheckbox: {
    marginTop: '15px',
  },

  textLineHeight: {
    lineHeight: '140%',
    paddingBottom: '10px',
  },
}));

const Contact = () => {
  const classes = useStyles();
  const [submitSuccessful, setSubmitSuccessful] = useState(null);
  const [reCaptchaToken, setReCaptchaToken] = useState(null);

  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      message: '',
      pageUrl: '',
      recaptcha: '',
    },
    onSubmit: async (values, helpers) => {
      try {
        // try to send email
        await sendContactForm(values, reCaptchaToken);
        // on success, show the 'thanks' message
        setSubmitSuccessful(true);
      } catch (err) {
        // and show some sort of error/retry message
        setSubmitSuccessful(false);
        helpers.setSubmitting(false);
      }
    },
    validate: (values) => {
      const errors = {};
      if (!values.firstName) {
        errors.firstName = 'Please enter first name';
      }
      if (!values.lastName) {
        errors.lastName = 'Please enter last name';
      }
      if (!values.email) {
        errors.email = 'Please enter email';
      } else if (
        !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(values.email)
      ) {
        errors.email = 'Please enter a valid email address';
      }
      if (values.phone) {
        if (!/^(\+44\s?\d{10}|0044\s?\d{10}|0\s?\d{10})?$/.test(values.phone)) {
          errors.phone = 'Please enter a valid phone number';
        }
      }
      if (!values.message) {
        errors.message = 'Please enter message';
      }
      if (!checked) {
        errors.checked = 'Please accept privacy policy to proceed';
      }
      if (!values.recaptcha) {
        errors.recaptcha = 'Please verify the Recaptcha box below';
      }
      return errors;
    },
  });

  const validateRecapture = (token) => {
    setReCaptchaToken(token);
    formik.values.recaptcha = token;
  };

  return (
    <Layout>
      <SEO title="Contact us" />
      {submitSuccessful === null ? (
        <div>
          <Container fixed className={classes.contactHeader}>
            <Typography variant="h1">Contact us</Typography>
            <Typography variant="body1">
              Feel free to contact us if you have a general enquiry or if you
              have spotted an error on our site.
              <br />
              <br />
              Alternatively, to add a service, use the&nbsp;
              <Link to="/add-service" className={classes.hyperlinkService}>
                add a service
              </Link>
              &nbsp;form.
            </Typography>
          </Container>
          <div>
            <form
              className={classes.formContainer}
              onSubmit={formik.handleSubmit}
            >
              <Grid item sm={5}>
                <FormTextInput
                  fieldName="firstName"
                  handleChange={formik.handleChange}
                  value={formik.values.firstName}
                  error={!!formik.touched.firstName && formik.errors.firstName}
                  showErrorText
                  boldText
                  label="First Name *"
                  handleBlur={formik.handleBlur}
                />
                <FormTextInput
                  fieldName="lastName"
                  handleChange={formik.handleChange}
                  value={formik.values.lastName}
                  error={!!formik.touched.lastName && formik.errors.lastName}
                  showErrorText
                  boldText
                  label="Last Name *"
                  handleBlur={formik.handleBlur}
                />
                <FormTextInput
                  fieldName="email"
                  handleChange={formik.handleChange}
                  value={formik.values.email}
                  error={!!formik.touched.email && formik.errors.email}
                  showErrorText
                  boldText
                  label="Email *"
                  handleBlur={formik.handleBlur}
                />
                <FormTextInput
                  fieldName="phone"
                  handleChange={formik.handleChange}
                  value={formik.values.phone}
                  error={!!formik.touched.phone && formik.errors.phone}
                  showErrorText
                  boldText
                  label="Phone number (optional)"
                  handleBlur={formik.handleBlur}
                />
                <FormTextInput
                  fieldName="message"
                  handleChange={formik.handleChange}
                  value={formik.values.message}
                  error={!!formik.touched.message && formik.errors.message}
                  label="Message *"
                  multiLine
                  boldText
                  rows={10}
                  showErrorText
                  handleBlur={formik.handleBlur}
                />
                <FormTextInput
                  fieldName="pageUrl"
                  handleChange={formik.handleChange}
                  value={formik.values.pageUrl}
                  label="Page URL (please include page URL if you have spotted an error)"
                  boldText
                  showErrorText
                  handleBlur={formik.handleBlur}
                />
                <div className={classes.privacyCheckbox}>
                  <FormPrivacyCheckbox
                    error={formik.errors.checked}
                    handleChange={handleChange}
                    checked={checked}
                    showTerms={false}
                  />
                </div>
                <div data-test-id="recaptcha-container">
                  <RecaptchaInput
                    errorMessage={
                      formik.submitCount > 0 && formik.errors.recaptcha
                    }
                    onChange={(response) => validateRecapture(response)}
                  />
                </div>
                <button
                  type="submit"
                  className={classes.submitButton}
                  // onClick={sendEmail}
                >
                  Send Message
                </button>
              </Grid>
            </form>
          </div>
        </div>
      ) : (
        <Grid item xs={12} sm={8}>
          {submitSuccessful === true && (
            <FormSubmission
              messageTitle="Thank you for contacting us"
              messageBody={
                <Box>
                  <Typography
                    variant="body2"
                    className={classes.textLineHeight}
                  >
                    Thank you very much for contacting the team at
                    cancercaremap.org
                  </Typography>

                  <Typography
                    variant="body2"
                    className={classes.textLineHeight}
                  >
                    Your message has been received and we will get back to you
                    as soon as possible - please note that we are a small team
                    therefore it may take a few days to process your enquiry.
                  </Typography>

                  <Typography
                    variant="body2"
                    className={classes.textLineHeight}
                  >
                    If there is something urgent you would like to speak to us
                    about please email us directly at hello@cancercaremap.org
                  </Typography>
                </Box>
              }
              pageLinks={[{ pageLink: '/', pageTitle: 'Go to the home page' }]}
            />
          )}
          {submitSuccessful === false && (
            <FormSubmission
              messageTitle="There has been an error with your submission."
              messageBody={
                <>
                  <Typography
                    variant="body2"
                    className={classes.textLineHeight}
                  >
                    Unfortunately something went wrong and we were not able to
                    send your message. Please try again. Apologies for the
                    inconvenience.
                  </Typography>
                  <br />
                  <button
                    type="button"
                    onClick={() => setSubmitSuccessful(null)}
                    className={classes.hyperLink}
                  >
                    Return to contact page
                  </button>
                </>
              }
            />
          )}
        </Grid>
      )}
    </Layout>
  );
};

export default Contact;
